import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Austin K. Garcia', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Austin Garcia',
  cta: 'Learn More',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    "I'm a mid-level software developer who constantly seeks out innovative solutions to everyday problems. In my 3 years in this industry, I've honed my analytical thinking and collaboration skills, and I love working with a team. I've also had the opportunity to serve as the software developer lead for three projects with Quadrint Inc.",
  paragraphTwo:
    '\n' +
    'I enjoy are spending time with my wife and family, mountain biking, piano, video games (Rocket League is my game of choice), and watching movies.',
  paragraphThree: `Fun fact about me is I'm an aspiring colorist`,
  resume: 'resume', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'blockvestment.png',
    title: 'BlockVestment',
    info: 'The Blockvestment Platform is a standalone application to find & manage both crypto and stock investments on a single platform. In progress.',
    info2: '',
    url: '',
    repo: 'https://github.com/BlockVestment/blockvestment-platform', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'hrsource.png',
    title: 'HR Source 2.0',
    info: 'The HR Source site is what we are building for our client. Rebuilding the site utilizing the best tech stack like React & Material UI.',
    info2: '',
    url: '',
    repo: 'https://github.com/BlockVestment/blockvestment-platform', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'k.austin.garcia@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/austinkg/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/austingarci',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
